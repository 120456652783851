import React from 'react';
import { Container, Form } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import Layout from "../components/layout";
import Seo from "../components/seo";

function team() {
    return (
        <Layout>
            <ScrollUpButton ContainerClassName="custom-scroll-button" />
            <Seo title="Submit a Request" />
            <Container className="py-5">
                <div className='submit-request'>
                    <div className="mb-4 mb-md-5">
                        <h2>Submit a Request</h2>
                        <p>Send us your request below</p>
                    </div>

                    <Form>
                        <div className="mb-md-4 mb-3">
                            <input type="text" className='form-control' placeholder='Title of the request' />
                        </div>
                        <div className="mb-md-4 mb-3 position-relative">
                            <textarea className='form-control' placeholder='Write your requests here!' rows="8" />
                            <Form.Group controlId="formFile" className='form-file mb-0'>
                                <Form.Label> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff" class="bi mr-1 bi-paperclip" viewBox="0 0 16 16">
                                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                                    </svg>

                                    Attach File
                                </Form.Label>
                                <Form.Control type="file" hidden />
                            </Form.Group>
                        </div>
                        <div className="mb-md-4 mb-3">
                            <input type="email" className='form-control' placeholder='Enter your email' />
                        </div>

                        <p className='pt-2' style={{color: '#787A8D'}}>Provide proof that supports to your request. <br />Example: info is reflected on website, announcement thread, twitter page, etc</p>
                        <div className='text-center'>
                            <button type="submit" className="text-white btn btn-primary">Submit</button>
                        </div>
                    </Form>
                </div>  
            </Container>
        </Layout>
    );
}

export default team;
